import styles from './SkinnyForm.module.scss'
import Section from 'components/Section'
import { Slider, Grid, Paper, FormControlLabel, Radio, RadioGroup, Divider } from '@achieve/sunbeam'
import { Typography, LinkButton } from 'components/Contentful'
import { TitleDivider } from 'components/TitleDivider'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'
import { useEffect, useState } from 'react'
import { get as _get } from 'lodash-es'
import { ANCHOR_POINTS, AchieveBadge } from 'components/AchieveBadge'
import { HandwrittenText } from 'components/HandwrittenText'

function SkinnyForm({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const {
    title,
    summary,
    eyebrow,
    exampleTitle,
    exampleLabelOne,
    exampleLabelTwo,
    exampleLabelThree,
    exampleLabelFour,
    exampleValueOne,
    exampleValueTwo,
    exampleValueThree,
    exampleValueFour,
    disclaimer,
    badgeExample,
    config,
    handwrittenRules,
  } = useMemoizedContentGetter(content, [
    'title',
    'summary',
    'eyebrow',
    'exampleTitle',
    'exampleLabelOne',
    'exampleLabelTwo',
    'exampleLabelThree',
    'exampleLabelFour',
    'exampleValueOne',
    'exampleValueTwo',
    'exampleValueThree',
    'exampleValueFour',
    'disclaimer',
    'badgeExample',
    'config',
    'handwrittenRules',
  ])
  const buttonActionImg = _get(handwrittenRules, 'gridItems[0]', {})
  const slider = config?.jsonContent?.slider || {
    min: 0,
    max: 100000,
    step: 1000,
    maxPlus: true,
  }
  const sliderQueryParam = config?.jsonContent?.sliderQueryParam || 'loan_amount'
  const ctaLinkBase =
    config?.jsonContent?.ctaLinkBase || '/get-started?brand=achieve&section=achieve_dr'
  const variant = {
    title: isMobile ? 'displayS20' : 'displayM20',
    titleFontWeight: 'medium',
    subtitle: 'displayS10',
    subtitleFontWeight: 'regular',
  }
  const sliderQuestion = config?.jsonContent?.sliderQuestion || 'How much debt do you have?'
  const sliderStatsTitle = config?.jsonContent?.sliderStatsTitle || 'Do you own your home?'
  const ctaText = config?.jsonContent?.ctaText || 'Get started'
  const ctaCustomizeText = config?.jsonContent?.ctaCustomizeText || 'Customize your plan'
  const ctaCustomizeLink =
    config?.jsonContent?.ctaCustomizeLink || '/get-started?brand=achieve&section=achieve_dr'
  const [sliderValue, setSliderValue] = useState(config?.jsonContent?.initialValue || 50000)
  const [ctaHref, setCtaHref] = useState('')
  const dollarUSLocale = Intl.NumberFormat('en-US')
  const [ownHome, setOwnHome] = useState(null)
  const [changeSlider, setChangeSlider] = useState(false)

  useEffect(() => {
    setCtaHref(`${ctaLinkBase}&${sliderQueryParam}=${sliderValue}&is_home_owner=${ownHome}`)
  }, [sliderValue, ctaLinkBase, ownHome, sliderQueryParam])

  function handleChange(e) {
    setSliderValue(e.target.value)
    setChangeSlider(true)
  }
  function handleChangeHome(e) {
    setOwnHome(e?.target?.value)
  }

  function ButtonContainer({ disabled = false }) {
    return (
      <Grid item xs={12} className={styles['button-container']}>
        <LinkButton
          track={{
            list_name: 'PRODUCT FORM',
            click_id: ctaText,
            hero_card_text: title,
            form_ss_amount: sliderValue,
            form_ss_homeowner: ownHome,
          }}
          content={{
            linkHref: ctaHref,
            linkText: ctaText,
          }}
          typographicOptions={{ variant: 'displayS10', fontWeight: 'bold' }}
          color="primary"
          variant="contained"
          data-testid="skinny-form-cta"
          disabled={disabled}
          fullWidth={isMobile}
        />
        {!isMobile && (
          <div className={styles['rule1']}>
            <HandwrittenText
              alt={buttonActionImg?.fields?.mediaAltText}
              content={
                isMobile
                  ? buttonActionImg?.fields?.mobileMediaContent
                  : buttonActionImg?.fields?.mediaContent
              }
            />
          </div>
        )}
      </Grid>
    )
  }
  return (
    <Section className={styles['section']} data-testid="skinny-form-section">
      <TitleDivider
        className={styles['paper-carousel-title-divider']}
        eyebrowClassName={styles['paper-carousel-title-eyebrow']}
        eyebrow={eyebrow}
        eyebrowBlack
        subtitle={!isMobile && summary}
        title={title}
        isMobile={isMobile}
        data-testid="skinny-form-title"
        variants={variant}
        subtitleTag="p"
      />

      <Grid
        container
        data-testid="desktop-product-hero-container"
        className={styles['slider-container']}
      >
        <Grid container item xs={12} md={5} xl={6}>
          <Grid item xs={12} md={10}>
            <Paper className={styles['slider-tool-paper']} data-testid="skinny-form-container">
              <Typography
                content={sliderQuestion}
                variant={isMobile ? 'displayXS30' : 'displayS10'}
                className={styles['slider-tool-title']}
                data-testid="skinny-form-title"
              />
              <div className={styles['slider-tool-wrapper']}>
                <Typography
                  content={`$${dollarUSLocale.format(sliderValue)}${
                    sliderValue === slider.max && slider.maxPlus ? '+' : ''
                  }`}
                  variant="displayXS30"
                  fontWeight="medium"
                  className={styles['slider-tool-value']}
                  data-testid="product-hero-dollar-value"
                />
                <Slider
                  aria-label="dollar-amount-selection-slider"
                  classes={{
                    mark: styles['marks'],
                  }}
                  data-testid="skinny-form-input"
                  value={sliderValue}
                  step={slider.step}
                  min={slider.min}
                  max={slider.max}
                  marks={[
                    {
                      value: slider.min,
                      label: (
                        <Typography
                          content={`$${dollarUSLocale.format(slider.min)}`}
                          variant="displayXS30"
                          fontWeight="regular"
                          className={styles['slider-tool-min-mark']}
                          data-testid="skinny-form-min"
                        />
                      ),
                    },
                    {
                      value: slider.max,
                      label: (
                        <Typography
                          content={`$${dollarUSLocale.format(slider.max)}${
                            slider.maxPlus ? '+' : ''
                          }`}
                          variant="displayXS30"
                          fontWeight="regular"
                          className={styles['slider-tool-max-mark']}
                          data-testid="skinny-form-max"
                        />
                      ),
                    },
                  ]}
                  onChange={handleChange}
                />
              </div>
              <Divider margin={2} className={styles['slider-divider']} />
              <Typography
                content={sliderStatsTitle}
                variant={isMobile ? 'displayXS30' : 'displayS10'}
                className={styles['slider-tool-stats-title']}
                data-testid="skinny-form-stats-title"
                data-disabled={!changeSlider}
              />
              {changeSlider && (
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={ownHome}
                  onChange={handleChangeHome}
                  className={styles['home-owner-radio-row']}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              )}
              <Divider margin={2} className={styles['slider-divider']} />
              {ButtonContainer({ disabled: ownHome === null })}
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} xl={4} alignSelf="flex-start">
          <Paper className={styles['example-paper']} data-testid="example-container">
            <AchieveBadge
              content={badgeExample?.mediaContent}
              height={70}
              width={70}
              anchor={ANCHOR_POINTS.RIGHT_TOP}
              translateX={-110}
              translateY={0}
            />
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  content={exampleTitle?.textContent}
                  variant="displayXS30"
                  fontWeight="bold"
                  data-testid="example-title"
                  className={styles['example-title']}
                />
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    content={exampleLabelOne?.textContent}
                    variant="displayXS30"
                    data-testid="example-label-one"
                  />
                  <Typography
                    content={exampleValueOne?.textContent}
                    variant="displayS10"
                    fontWeight="bold"
                    className={styles['example-value']}
                    data-testid="example-value-one"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    content={exampleLabelTwo?.textContent}
                    variant="displayXS30"
                    data-testid="example-label-two"
                  />
                  <Typography
                    content={exampleValueTwo?.textContent}
                    variant="displayS10"
                    fontWeight="bold"
                    className={styles['example-value']}
                    data-testid="example-value-two"
                  />
                </Grid>
              </Grid>
              <Grid container className={styles['example-divider']}>
                <Grid item xs={6}>
                  <Typography
                    content={exampleLabelThree?.textContent}
                    variant="displayXS30"
                    data-testid="example-label-three"
                  />
                  <Typography
                    content={exampleValueThree?.textContent}
                    variant="displayS10"
                    fontWeight="bold"
                    className={styles['example-value']}
                    data-testid="example-value-three"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    content={exampleLabelFour?.textContent}
                    variant="displayXS30"
                    data-testid="example-label-four"
                  />
                  <Typography
                    content={exampleValueFour?.textContent}
                    variant="displayS10"
                    fontWeight="bold"
                    className={styles['example-value']}
                    data-testid="example-value-four"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid className={styles['disclaimer-container']}>
            <Typography
              content={disclaimer?.textContent}
              variant="bodyS20"
              data-testid="example-disclaimer"
            />
          </Grid>
          {isMobile && (
            <LinkButton
              track={{
                list_name: 'PRODUCT FORM CTA',
              }}
              content={{
                linkHref: ctaCustomizeLink,
                linkText: ctaCustomizeText,
              }}
              typographicOptions={{ variant: 'displayS10', fontWeight: 'bold' }}
              color="primary"
              variant="text"
              data-testid="skinny-form-cta"
              fullWidth={isMobile}
            />
          )}
        </Grid>
      </Grid>
    </Section>
  )
}
export { SkinnyForm }
export default SkinnyForm
